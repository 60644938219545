<template>
  <div>
    <form-base
      v-model="customer"
      @handle-duplicate-error="handleDuplicateError"
      @update-form-status="updateFormBaseStatus"
    />
    <form-detail
      v-model="customer"
    />

    <attachments-create
      ref="attachments"
      v-model="customer"
      @update-files="val => customer.document_files = val"
      @all-updated="handleAllUpdated"
    />

    <actions
      :buttons="actionsButton"
      @click="handleActionsButton"
    />
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import customerStoreModule from '@/views/customer-hotel/customerStoreModule'
import useCustomerCreate from '@/views/customer-hotel/create/useCustomerCreate'
import FormBase from '@/views/customer-hotel/create/FormBase.vue'
import FormDetail from '@/views/customer-hotel/create/FormDetail.vue'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import AttachmentsCreate from '@/views/components/util/AttachmentsCreate.vue'

export default {
  components: {
    FormBase,
    FormDetail,
    AttachmentsCreate,
    Actions,
  },
  computed: {
    actionsButton() {
      const validActions = []
      validActions.push(this.ACTIONS_BUTTON.CREATE)
      return validActions
    },
  },
  created() {
    this.customer.province_id = 1
    this.customer.type_product = 'hired'
    this.customer.formality = 'thue'
    this.customer.product_type = 'hotel'
    this.customer.price = 'usd'
    this.customer.type = 'khach-san'
  },
  methods: {
    updateFormBaseStatus(statusMsg) {
      this.formBaseStatus = statusMsg
    },
    handleDuplicateError(statusMsg) {
      this.duplicateError = statusMsg
    },
    handleAllUpdated(files) {
      if (!this.isUploading) return
      this.customer.document_files = files
      this.handleCreateCustomer()
    },
    handleActionsButton(btn) {
      if (btn.value === 'create') {
        const pending = this.$refs.attachments.$refs.uploads.$refs.uploads.getQueuedFiles()
        if (pending && pending.length) {
          this.isUploading = true
          this.$refs.attachments.$refs.uploads.$refs.uploads.processQueue()
        } else {
          this.handleCreateCustomer()
        }
      }
    },
    handleCreateCustomer() {
      if (this.duplicateError) {
        this.$showNoti(this.duplicateError, 'warning')
        return
      }
      const errs = Array.isArray(this.formBaseStatus) ? this.formBaseStatus.filter(item => item) : [this.formBaseStatus].filter(item => item)
      if (this.formBaseStatus && errs && errs.length) {
        this.$showNoti(errs.join(', '), 'warning')
        return
      }
      if (!this.customer.title || !this.customer.content) {
        this.$showNoti('Cần cập nhật chi tiết trước khi tạo', 'warning')
        return
      }
      this.customer.image = this.customer.image || (this.customer.document_files.length ? this.customer.document_files[0] : {}).origin || ''
      this.$call(this.createCustomer(this.customer), true).then(({ id }) => {
        this.$router.push({ name: `customer-${this.customerMenuType}-detail`, params: { id } })
      })
    },
    getFormalityCustomer() {
      return []
    },
  },
  setup() {
    const CUSTOMER_STORE_MODULE_NAME = 'customer'
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
    })
    return {
      ...useCustomerCreate(),
    }
  },
}
</script>

<style lang="scss">
</style>
